







































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

export interface Offer {
  id: number;
  name: string;
  credits: number;
  price: number;
}

@Component
export default class CountryItem extends Vue {
  public userRole: any = 'staff'
  public userUrl:any = 'staff'
  @Prop() offer!: Offer
  mounted () {
    let userData = this.$store.state.userData
    this.userRole = userData.role
    if (this.userRole === 'super-admin') {
      this.userUrl = 'sadmin'
    } else {
      this.userUrl = 'staff'
    }
  }

  setOfferIdToDelete (offerId:number) {
    this.$emit('setOfferIdToDelete', offerId)
  }

  createOfferLink (offer: any) {
    this.$emit('createOfferLink', offer)
  }
}
