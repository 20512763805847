















































import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'
import DatePicker from 'vue2-datepicker'

@Component({
  components: {
    DatePicker
  }
})
export default class OfferLinkPopup extends Vue {
  @Prop() popupId!: string
  @Prop() offerId!: number

  public expireDate: Date = new Date()
  public createdSecretLink: String = ''

  sendOfferData () {
    this.$validator.validateAll('crate-offer-link').then((result) => {
      if (result) {
        let payload = {
          'offerId': this.offerId,
          'expireDate': this.expireDate
        }
        this.$store.dispatch('craeteOfferLink', payload).then((response) => {
          console.log(response.body)
          if (response.status === 200) {
            this.$notify({ type: 'success', text: 'Link has been generated successully' })
            this.createdSecretLink = process.env.VUE_APP_URL + '/brand/offers/fetch?secret=' + response.body.secret
          }
        }, (response) => {
        })
      }
    })
  }
}
