









































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class DeleteOfferPopup extends Vue {
  @Prop() popupId!: string
  @Prop() offerId!: number

  deleteOffer () {
    if (this.offerId === 0) {
      return false
    }
    this.$store.dispatch('deleteOffer', { offerId: this.offerId }).then((response) => {
      if (response.status === 204) {
        let DeleteOfferButtonPopup: HTMLElement = this.$refs.DeleteOfferButtonPopup as HTMLElement
        DeleteOfferButtonPopup.click()
        this.$emit('offerDeleted')
      }
    }, (response) => {

    })
  }
}
