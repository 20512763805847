
























import OfferItem from '@/components/SAdmin/OfferItem.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import DeleteOfferPopup from '@/components/SAdmin/DeleteOfferPopup.vue'
import OfferLinkPopup from '@/components/SAdmin/OfferLinkPopup.vue'

@Component({
  components: {
    OfferItem,
    DeleteOfferPopup,
    OfferLinkPopup
  }
})
export default class Countries extends Vue {
  public query = ''
  public limit = 500
  public offset = 0
  public actionCountry: any = null
  public offers: any[] = []
  public totalCount = 0
  public busy: boolean = false
  public offerIdtoDelete: number = 0
  public offerLink: string = ''
  public offerIdToCraeteLink: number = 0

  createOfferLink (offer:any) {
    this.offerIdToCraeteLink = offer.id
  }

  getOffers (clear = true) {
    this.busy = true
    this.$store.dispatch('getOffersList', { query: this.query, limit: this.limit, offset: this.offset }).then((response) => {
      if (clear) this.offers = []
      this.offers.push(...response.response.body.offers)
      this.totalCount = response.response.body.count
      this.busy = false
    }, (response) => {
      this.busy = false
    })
  }

  resetOffersList () {
    this.offers = []
    this.offset = 0
    this.getOffers()
  }

  setOfferIdToDelete (offerId:number) {
    this.offerIdtoDelete = offerId
  }

  loadMore () {
    this.$data.offset += this.$data.limit
    this.getOffers(false)
  }

  @Watch('query')
  onSearch () {
    Vue.common.delay(() => {
      this.offset = 0
      this.getOffers()
    }, 500)()
  }

  created () {
    this.getOffers()
    this.$store.dispatch('getAppSettingsKeyValuePair')
  }

  refresh () {
    this.offset = 0
    this.getOffers()
  }

  get hasLoadMore (): boolean {
    return this.offers.length < this.totalCount
  }
}
