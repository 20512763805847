var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "green-border-box outer-box" }, [
    _c("div", { staticClass: "inner-box" }, [
      _c("span", { staticClass: "title", attrs: { title: _vm.offer.name } }, [
        _vm._v(_vm._s(_vm.offer.name))
      ]),
      _c("br"),
      _c(
        "span",
        { staticClass: "description", attrs: { title: _vm.offer.price } },
        [
          _vm._v(
            "In $" +
              _vm._s(_vm.offer.price) +
              " | " +
              _vm._s(_vm.offer.credits) +
              " Credits"
          )
        ]
      ),
      _c("br"),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _vm.offer.type !== "PRE_DEFINED"
            ? _c(
                "button",
                {
                  staticClass: "btn-negative pull-left",
                  attrs: {
                    "data-target": "#delete-offer-popup",
                    "data-toggle": "modal",
                    type: "button"
                  },
                  on: {
                    click: function($event) {
                      return _vm.setOfferIdToDelete(_vm.offer.id)
                    }
                  }
                },
                [_vm._v("DELETE")]
              )
            : _c(
                "button",
                {
                  staticClass: "btn-negative pull-left",
                  attrs: {
                    "data-target": "#delete-offer-popup",
                    "data-toggle": "modal",
                    type: "button"
                  },
                  on: {
                    click: function($event) {
                      return _vm.setOfferIdToDelete(_vm.offer.id)
                    }
                  }
                },
                [_vm._v("PRE DEFINED OFFER")]
              ),
          _c(
            "button",
            {
              staticClass: "btn-positive",
              attrs: {
                "data-target": "#offer-link-popup",
                "data-toggle": "modal",
                type: "button"
              },
              on: {
                click: function($event) {
                  return _vm.createOfferLink(_vm.offer)
                }
              }
            },
            [_vm._v("CREATE OFFER LINK")]
          ),
          _c(
            "router-link",
            {
              staticClass: "btn-positive pull-right",
              attrs: { to: "/sadmin/offers/" + _vm.offer.id + "/edit" }
            },
            [_vm._v("\n        Edit\n      ")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }